import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for the user
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// Provider component to wrap around parts of the app that need access to user data
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    // Load user data from localStorage when the component mounts
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    // Function to update the user data
    const loginUser = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData)); // Save to localStorage
    };

    // Function to log out the user and clear the session
    const logoutUser = () => {
        setUser(null);
        localStorage.removeItem('user'); // Clear localStorage
    };

    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};
