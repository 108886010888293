// src/Components/ProtectedRoute/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../userContext'; // Custom hook to get user state

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading state for initial user data fetching
        if (user !== undefined) {
            setLoading(false);
        }
    }, [user]);

    if (loading) {
        // While loading, you can render a loading spinner or a blank screen
        return <div>Loading...</div>;
    }

    if (!user) {
        // If user is not logged in, redirect to login page
        return <Navigate to="/login" replace />;
    }

    // If user is logged in, render the requested component
    return <Component {...rest} />;
};

export default ProtectedRoute;
