import { useState } from 'react';
import { Link } from 'react-router-dom';
import { wait } from "@testing-library/user-event/dist/utils";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        wait(1.5).then(r => setIsOpen(!isOpen))
    };

    return (
        <nav className={`flex flex-col justify-between items-center z-50 fixed top-0 left-0 right-0 shadow-2xl px-4 transition-height duration-500 ease-in-out ${isOpen ? 'h-auto' : 'h-16'}`} style={{ WebkitBackdropFilter: 'blur(40px)', background: 'rgba(255,255,255,0.6)', backdropFilter: 'blur(40px)' }}>
            <div className="flex justify-between items-center w-full h-16">
                <Link className="text-black font-bold px-4 py-2 m-2" to="/">METABOL</Link>
                <div className="hidden md:flex flex-grow justify-center items-center space-x-4">
                    <Link className="text-sm md:text-base text-black px-4 py-2 m-2 hover:text-blue-400" to="#features">Features</Link>
                    <Link className="text-sm md:text-base text-black px-4 py-2 m-2 hover:text-blue-400" to="#pricing">Pricing</Link>
                </div>
                <div className="hidden md:flex space-x-4 items-center ml-auto">
                    <Link className="text-sm md:text-base text-white px-4 py-2 m-2 hover: rounded-md border border-b-fuchsia-500 bg-purple-600" to="/signup">Sign up</Link>
                    <Link className="text-sm md:text-base text-white px-4 py-2 m-2 hover:text-blue-400 rounded-md border border-b-fuchsia-500 bg-purple-600" to="/login">Log in</Link>
                </div>
                <button
                    className="text-black md:hidden"
                    onClick={toggleMenu}
                >
                    <svg className="w-6 h-6" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            <div className={`flex flex-col items-center w-full px-4 mb-6 transition-opacity duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'} md:hidden`}>
                <div className="flex flex-col items-center w-full space-y-4"> {/* Added consistent spacing */}
                    <Link className="block font-bold text-black font-bold px-4 py-2 hover:text-blue-400" to="#features">Features</Link>
                    <Link className="block font-bold text-black font-bold px-4 py-2 hover:text-blue-400" to="#pricing">Pricing</Link>
                    <Link className="block w-full text-center text-purple-600 font-bold py-3 border border-purple-600 rounded-lg mx-4" to="/login">Log in</Link> {/* Adjusted width and margins */}
                    <Link className="block w-full text-center text-white font-bold py-3 bg-purple-600 border border-purple-700 rounded-lg mx-4" to="/signup">Try for Free →</Link> {/* Adjusted width and margins */}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
