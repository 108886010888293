import React, { useState } from 'react';
import logo from '../../Assets/metabol-logo-white-bg.svg';
import { useUser } from '../../userContext';

function VerticalNavBar({ onAccountClick, onLogout, onConversationsClick, onWorkoutsClick, onSmartDashboardClick }) {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const { user } = useUser();

    return (
        <div>
            <button
                className={`fixed top-0 left-0 w-12 h-12 p-2 m-1 rounded ${isNavOpen ? 'hidden' : 'block'} text-white bg-gray-800 z-20 md:hidden`}
                onClick={() => setIsNavOpen(true)}
                style={{ zIndex: 50 }}
            >
                ☰
            </button>

            <div className={`fixed top-0 left-0 md:z-0 h-full w-full md:w-1/6 bg-gray-800 text-white flex flex-col justify-between p-4 transform ${isNavOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-30 md:translate-x-0`}>

                <button
                    className="self-end text-white mb-4 md:hidden"
                    onClick={() => setIsNavOpen(false)}
                >
                    ← Close
                </button>

                <div className="flex flex-col items-center">
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-32 h-32 mb-2"
                    />
                    <p className="text-center text-sm">{user.email}</p>
                </div>

                <hr className="my-4 border-gray-600" />

                <nav className="space-y-4 flex-grow mt-6">
                    <button
                        className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700"
                        onClick={onAccountClick}
                    >
                        Account
                    </button>
                    <button
                        id="past-conversations"
                        className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700"
                        onClick={onConversationsClick}
                    >
                        Conversations
                    </button>
                    <button className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700">
                        Daily activity
                    </button>
                    <button
                        id="past-workouts"
                        className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700"
                        onClick={onWorkoutsClick}
                    >
                        Workout Program
                    </button>
                    <button className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700">
                        Diet plan
                    </button>
                    <button
                        className="block w-full text-center px-4 py-2 rounded hover:bg-gray-700"
                        onClick={onSmartDashboardClick}
                    >
                        Smart Dashboard
                    </button>

                </nav>

                <div className="mt-auto">
                    <button
                        className="block w-full text-center px-4 py-2 rounded bg-red-500 hover:bg-red-600 mt-6"
                        onClick={onLogout}
                    >
                        Log Out
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VerticalNavBar;
