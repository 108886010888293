import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../userContext';
import Footer from "../../Components/Footer/footer";
import AccountSetup from "../../Components/AccountSetup/accountSetup";
import PastConversations from "../../Components/PastConversations/pastConversations";
import PastWorkouts from '../../Components/PastWorkouts/pastWorkouts';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import VerticalNavBar from '../../Components/VerticalNavBar/verticalNavBar';
import SmartDashboard from '../../Components/SmartDashboard/smartDashboard';
import ChatBubleLauncher from '../../Components/ChatBubleLauncher/chatBubleLauncher';
import Tutorial from '../../Components/Tutorial/tutorial'; // Import the Tutorial component
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'; // Firebase storage functions
import { storage } from '../../firebaseConfig'; // Import your Firebase storage

function UserSpacePage() {
    const [showAccountSetup, setShowAccountSetup] = useState(false);
    const [showConversations, setShowConversations] = useState(false);
    const [showSmartDashboard, setShowSmartDashboard] = useState(false);
    const [showWorkouts, setShowWorkouts] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false); // Add tutorial state
    const { user, logoutUser } = useUser();
    const navigate = useNavigate();

    // Fetch the user's profile from Firebase storage and check if 'justSignedUp' is true
    useEffect(() => {
        const fetchProfile = async () => {
            if (user) {
                try {
                    const profileRef = ref(storage, `${user.uid}/Account/account.json`);
                    const url = await getDownloadURL(profileRef);
                    const response = await fetch(url);
                    const data = await response.json();

                    // Check if the user has just signed up
                    if (data.justSignedUp === "true") {
                        setShowTutorial(true); // Show tutorial if it's the user's first time
                    }
                } catch (error) {
                    console.error('Error fetching profile data:', error);
                }
            }
        };

        fetchProfile();
    }, [user]);

    const handleAccountClick = () => {
        setShowAccountSetup(true);
        setShowConversations(false);
        setShowSmartDashboard(false);
        setShowWorkouts(false);
    };

    const handleConversationsClick = () => {
        setShowConversations(true);
        setShowAccountSetup(false);
        setShowSmartDashboard(false);
        setShowWorkouts(false);
    };

    const handleSmartDashboardClick = () => {
        setShowSmartDashboard(true);
        setShowAccountSetup(false);
        setShowConversations(false);
        setShowWorkouts(false);
    };

    const handleWorkoutsClick = () => {
        setShowWorkouts(true);
        setShowAccountSetup(false);
        setShowConversations(false);
        setShowSmartDashboard(false);
    };

    const handleClose = () => {
        setShowAccountSetup(false);
        setShowConversations(false);
        setShowSmartDashboard(false);
        setShowWorkouts(false);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            logoutUser();
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    // Function to update the account.json file and set 'justSignedUp' to 'false'
    const handleFinishTutorial = async () => {
        setShowTutorial(false);

        try {
            const profileRef = ref(storage, `${user.uid}/Account/account.json`);
            const url = await getDownloadURL(profileRef);
            const response = await fetch(url);
            const data = await response.json();

            // Update the 'justSignedUp' flag to 'false'
            data.justSignedUp = "false";

            // Convert the updated data back to a JSON blob
            const updatedFile = new Blob([JSON.stringify(data)], { type: 'application/json' });

            // Upload the updated file back to Firebase storage
            await uploadBytes(profileRef, updatedFile);
            console.log('Tutorial status updated successfully.');
        } catch (error) {
            console.error('Error updating profile data:', error);
        }
    };

    if (!user) {
        return <p>Loading user data...</p>;
    }

    return (
        <div className="flex min-h-screen flex-col md:flex-row">
            <div className="w-full md:w-1/6">
                <VerticalNavBar
                    onAccountClick={handleAccountClick}
                    onLogout={handleLogout}
                    onConversationsClick={handleConversationsClick}
                    onSmartDashboardClick={handleSmartDashboardClick}
                    onWorkoutsClick={handleWorkoutsClick}
                />
            </div>
            <div className="flex-1 flex flex-col relative">
                {showAccountSetup ? (
                    <AccountSetup onClose={handleClose} />
                ) : showConversations ? (
                    <PastConversations onClose={handleClose} />
                ) : showSmartDashboard ? (
                    <SmartDashboard onClose={handleClose} />
                ) : showWorkouts ? (
                    <PastWorkouts onClose={handleClose} />
                ) : (
                    <SmartDashboard onClose={handleClose} />
                )}

                {/* Include the ChatBubleLauncher */}
                <ChatBubleLauncher />

                <Footer />
            </div>

            {/* Show tutorial if it's the user's first time */}
            {showTutorial && <Tutorial onFinish={handleFinishTutorial} />}
        </div>
    );
}

export default UserSpacePage;
