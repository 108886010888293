import React, {useState, useEffect, useRef} from 'react';
import { storage } from '../../firebaseConfig'; // Import Firebase Storage
import { ref, listAll, getDownloadURL } from 'firebase/storage'; // Import Storage methods
import { useUser } from '../../userContext'; // Import user context to get current user
import ReactMarkdown from 'react-markdown'; // Import react-markdown

function PastConversations({ onClose }) {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null); // State to manage selected conversation
    const [loading, setLoading] = useState(true); // Loading state
    const { user } = useUser(); // Get the current logged-in user
    const overlayRef = useRef(null);

    const handleClickOutside = (event) => {
        // Check if the click was outside the overlay content
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setSelectedConversation(null);
        }
    };

    useEffect(() => {
        // Add event listener to handle clicks outside the overlay
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (user) {
            // Fetch the list of JSON files from the user's directory
            const fetchConversations = async () => {
                try {
                    const storageRef = ref(storage, `${user.uid}/User Conversations/`);
                    const fileList = await listAll(storageRef);

                    // Retrieve URLs for each file
                    const files = await Promise.all(
                        fileList.items.map(async (itemRef) => {
                            const url = await getDownloadURL(itemRef);
                            const response = await fetch(url);
                            const data = await response.json();
                            return { name: itemRef.name, data };
                        })
                    );

                    setConversations(files);
                } catch (error) {
                    console.error('Error fetching conversations:', error);
                } finally {
                    setLoading(false); // Set loading to false after fetching
                }
            };

            fetchConversations();
        }
    }, [user]);

    // Function to handle displaying the conversation overlay
    const handleConversationClick = (conversation) => {
        setSelectedConversation(conversation);
    };

    // Function to close the conversation overlay
    const closeOverlay = () => {
        setSelectedConversation(null);
    };

    return (
        <div className="relative p-4 h-full flex flex-col overflow-hidden">
            <h2 className="text-xl font-semibold mb-4">Past Conversations</h2>
            <div className="flex-1 overflow-y-auto mb-16"> {/* Extra margin to account for fixed button */}
                {loading ? (
                    // Loading spinner
                    <div className="flex justify-center items-center h-full">
                        <div className="loader"></div>
                    </div>
                ) : conversations.length > 0 ? (
                    <ul className="space-y-2">
                        {conversations.map((conversation, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => handleConversationClick(conversation)}
                                    className="w-full bg-red-500 text-white py-2 rounded text-left px-4"
                                >
                                    {conversation.name.replace('.json', '')} {/* Display the date without .json */}
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-center">No conversations found.</p>
                )}
            </div>

            {/* Overlay to display selected conversation */}
            {selectedConversation && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeOverlay}>
                    <div
                        ref={overlayRef}
                        className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-lg h-5/6 relative z-60 overflow-y-auto overflow-x-hidden"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="font-bold mb-4">{selectedConversation.name.replace('.json', '')}</h3>
                        <div className="flex flex-col space-y-4 overflow-y-auto overflow-x-hidden">
                            {selectedConversation.data.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`p-3 rounded-lg max-w-full md:max-w-lg break-words ${
                                        msg.sender === 'user'
                                            ? 'bg-blue-100 text-blue-900 self-end'
                                            : 'bg-gray-800 text-white self-start'
                                    }`}
                                    style={{ width: 'fit-content', maxWidth: '75%' }}
                                >
                                    <ReactMarkdown>{msg.text}</ReactMarkdown> {/* Render message text as markdown */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <style jsx>{`
                .loader {
                    border: 4px solid #f3f3f3; /* Light grey */
                    border-top: 4px solid #f56565; /* Red */
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    animation: spin 2s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
}

export default PastConversations;
