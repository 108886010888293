import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../../firebaseConfig'; // Import Firebase Storage
import { ref, listAll, getDownloadURL } from 'firebase/storage'; // Import Storage methods
import { useUser } from '../../userContext'; // Import user context to get current user
//import ReactMarkdown from 'react-markdown'; // Import react-markdown

function PastWorkouts({ onClose }) {
    const [workouts, setWorkouts] = useState([]); // State to store workout files
    const [selectedWorkout, setSelectedWorkout] = useState(null); // State to manage selected workout
    const [loading, setLoading] = useState(true); // Loading state
    const { user } = useUser(); // Get the current logged-in user
    const overlayRef = useRef(null); // Reference for detecting clicks outside the overlay

    const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setSelectedWorkout(null); // Deselect workout on outside click
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (user) {
            const fetchWorkouts = async () => {
                try {
                    const storageRef = ref(storage, `${user.uid}/User Workouts/`); // Path to user's workout directory
                    const fileList = await listAll(storageRef); // Fetch file list

                    const files = await Promise.all(
                        fileList.items.map(async (itemRef) => {
                            const url = await getDownloadURL(itemRef); // Get file download URL
                            const response = await fetch(url); // Fetch file data
                            const data = await response.json(); // Parse the JSON content
                            return { name: itemRef.name, data }; // Return file name and data
                        })
                    );

                    setWorkouts(files); // Set the workouts state
                } catch (error) {
                    console.error('Error fetching workouts:', error);
                } finally {
                    setLoading(false); // Set loading state to false
                }
            };

            fetchWorkouts();
        }
    }, [user]);

    const handleWorkoutClick = (workout) => {
        setSelectedWorkout(workout); // Set selected workout on click
    };

    const closeOverlay = () => {
        setSelectedWorkout(null); // Close the overlay
    };

    return (
        <div className="relative p-4 h-full flex flex-col overflow-hidden">
            <h2 className="text-xl font-semibold mb-4">Past Workouts</h2>
            <div className="flex-1 overflow-y-auto mb-16">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <div className="loader"></div>
                    </div>
                ) : workouts.length > 0 ? (
                    <ul className="space-y-2">
                        {workouts.map((workout, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => handleWorkoutClick(workout)}
                                    className="w-full bg-blue-500 text-white py-2 rounded text-left px-4"
                                >
                                    {workout.name.replace('.json', '')} {/* Show workout name without .json */}
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-center">No workouts found.</p>
                )}
            </div>

            {selectedWorkout && selectedWorkout.data.exercises && ( // Ensure the data contains exercises
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeOverlay}>
                    <div
                        ref={overlayRef}
                        className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-lg h-5/6 relative z-60 overflow-y-auto overflow-x-hidden"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="font-bold mb-4">{selectedWorkout.name.replace('.json', '')}</h3>
                        <div className="flex flex-col space-y-4 overflow-y-auto overflow-x-hidden">
                            {selectedWorkout.data.exercises.map((exercise, index) => (
                                <div key={index} className="p-3 rounded-lg bg-gray-100 text-gray-900">
                                    <h4 className="font-semibold">{exercise.name}</h4>
                                    <p>{exercise.description}</p>
                                    <p><strong>Sets:</strong> {exercise.sets}</p>
                                    <p><strong>Reps:</strong> {exercise.reps}</p>
                                    <p><strong>Weight:</strong> {exercise.weight}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <style jsx>{`
                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #4299e1;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    animation: spin 2s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
}

export default PastWorkouts;
