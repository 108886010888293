import React, { useState, useRef, useEffect } from 'react';
import ChatArea from "../../Components/ChatArea/chatArea";

function ChatBubleLauncher() {
    const [showChatOverlay, setShowChatOverlay] = useState(false);
    const overlayRef = useRef(null);

    const toggleChatOverlay = () => {
        setShowChatOverlay(!showChatOverlay);
    };

    const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setShowChatOverlay(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${showChatOverlay ? "fixed inset-0 bg-black bg-opacity-50 z-50" : ""}`}>
            <div className="fixed bottom-5 right-5 z-50">
                <div
                    className="relative w-20 h-20 bg-red-500 rounded-full flex items-center justify-center cursor-pointer"
                    style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)' }}
                    onClick={toggleChatOverlay}
                >
                    <div
                        className="relative w-12 h-12 bg-white rounded-full shadow-2xl flex items-center justify-center"
                        style={{ boxShadow: '0 15px 40px rgba(0, 0, 0, 0.7)' }}
                    >
                        <div className="flex space-x-1">
                            <span className="w-2 h-2 bg-gray-800 rounded-full"></span>
                            <span className="w-2 h-2 bg-gray-800 rounded-full"></span>
                            <span className="w-2 h-2 bg-gray-800 rounded-full"></span>
                        </div>
                    </div>
                </div>

                {showChatOverlay && (
                    <div
                        ref={overlayRef}
                        className="fixed flex flex-col justify-end bottom-28 right-5 bg-white rounded-lg shadow-lg z-50 overflow-hidden"
                        style={{ minWidth: '40vw', minHeight: '400px', maxWidth: '60vw', maxHeight: '500px' }} // Ensure proper sizing and visibility
                    >
                        <ChatArea />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChatBubleLauncher;
