import { Link } from "react-router-dom";

function SalesScript() {
    return (
        <div>
            {/* Existing content */}
            <div className="flex justify-center mt-24 md:mt-48">
                <div className="bg-gray-200 text-purple-600 font-bold text-xs md:text-sm py-1 px-3 rounded-full">
                    Powered by AI
                </div>
            </div>
            <div className="mt-6 md:mt-10 text-center text-black font-bold text-4xl md:text-6xl my-2 md:my-4">
                The new <span className="text-purple-600">coach</span> you've <br className="hidden md:block" /> always dreamt about
            </div>
            <div className="mt-8 md:mt-16 px-8 md:px-40 text-center text-gray-600 text-base md:text-[23px]">
                Create your account, fill in your profile sheet, build your very own personalized training program and nutrition plan... <br className="hidden md:block" />
                That's how close you are from improving your health and fitness.
            </div>

            {/* Adjusted Try for Free Button */}
            <Link
                className="block w-full max-w-xs md:max-w-sm lg:max-w-md text-center text-white font-bold py-3 bg-purple-600 border border-purple-700 rounded-lg mx-auto mt-6"
                to="/signup"
            >
                Try for Free →
            </Link>

            <section className="w-full mb-10">
                <div className="z-10 w-full flex flex-col justify-center items-center min-h-screen">
                    <div className="inset-0 flex justify-center items-center">
                        <div className="w-full md:w-3/4 lg:w-2/3 h-auto bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-3xl shadow-2xl p-4 md:p-12 mx-4">
                            <section className="p-6 md:p-12 z-10 relative w-full">
                                <p className="text-center text-white text-base md:text-lg">
                                    This is a frosted glass effect box with some sample content.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            {/* Black Section under the Title + Subtitle */}
            <div className="bg-black text-white py-10">
                <div className="flex justify-center mt-24 md:mt-48">
                    <div className="bg-gray-200 text-purple-600 font-bold text-xs md:text-sm py-1 px-3 rounded-full">
                        Powered by AI
                    </div>
                </div>
                <div className="mt-6 md:mt-10 text-center text-white font-bold text-4xl md:text-6xl my-2 md:my-4">
                    The new <span className="text-purple-600">coach</span> you've <br className="hidden md:block" /> always dreamt about
                </div>
                <div className="mt-8 md:mt-16 px-8 md:px-40 text-center text-gray-600 text-base md:text-[23px] my-4 md:my-6">
                    Create your account, fill in your profile sheet, build your very own personalized training program and nutrition plan... <br className="hidden md:block" />
                    That's how close you are from improving your health and fitness.
                </div>
            </div>
        </div>
    );
}

export default SalesScript;
