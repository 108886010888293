/*import { useState } from 'react';
import { Link } from 'react-router-dom';
import {wait} from "@testing-library/user-event/dist/utils";*/

function Footer() {
    return (
        <div>

        </div>
    );
}

export default Footer;
