import { ref, getDownloadURL, listAll } from 'firebase/storage'; // Import Firebase Storage methods
import { useEffect, useState } from 'react';
import { format, startOfWeek, addDays, isBefore, isToday } from 'date-fns'; // Library for date handling
import { useUser } from '../../userContext'; // Import user context to get current user
import { storage } from '../../firebaseConfig'; // Import Firebase Storage

const SmartDashboard = () => {
    const [weeklyData] = useState({
        caloriesBurned: 2500,
        trainingsDone: 5,
        achievements: ['Increased bench press weight', 'Ran 5k under 25 minutes'],
    });
    const [currentWeek, setCurrentWeek] = useState([]);
    const [goal, setGoal] = useState(''); // Default goal
    const { user } = useUser(); // Get the current logged-in user

    useEffect(() => {
        if (user) {
            // Fetch the goal from the user's profile sheet
            const fetchGoal = async () => {
                try {
                    const storageRef = ref(storage, `${user.uid}/Profile Sheet/`);
                    const fileList = await listAll(storageRef);

                    // Find the file that matches the user's UID
                    const profileRef = fileList.items.find(item => item.name === `${user.uid}.json`);

                    if (profileRef) {
                        const url = await getDownloadURL(profileRef);
                        const response = await fetch(url);
                        const data = await response.json();

                        console.log(data);

                        // Assuming the profile sheet has a 'goals' key
                        setGoal(data.goals || '');
                    } else {
                        console.log('No profile file found.');
                    }
                } catch (error) {
                    console.error('Error fetching user goal:', error);
                }
            };

            fetchGoal();

            // Set the current week days
            const start = startOfWeek(new Date(), { weekStartsOn: 1 }); // Start from Monday
            const days = Array.from({ length: 7 }, (_, i) => addDays(start, i));
            setCurrentWeek(days);
        }
    }, [user]);

    const isPastDay = (date) => isBefore(date, new Date()) && !isToday(date);
    const getCurrentDayProgress = () => {
        const now = new Date();
        const startOfDay = new Date(now.setHours(0, 0, 0, 0));
        const endOfDay = new Date(now.setHours(23, 59, 59, 999));
        return ((Date.now() - startOfDay) / (endOfDay - startOfDay)) * 100;
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md h-screen w-full overflow-hidden flex flex-col">
            <h1 className="text-2xl font-bold text-black mb-4">Your goal with Metabol: {goal}</h1>

            <div className="grid grid-cols-7 gap-2 mb-6">
                {currentWeek.map((day, index) => {
                    const dayFormatted = format(day, 'EEEE, MMM d');
                    const isPast = isPastDay(day);
                    const isCurrent = isToday(day);
                    const dayProgress = isCurrent ? getCurrentDayProgress() : 0;

                    return (
                        <div
                            key={index}
                            className={`relative text-center p-4 rounded border-2 ${isPast ? 'bg-green-300' : 'bg-gray-100'}`}
                        >
                            {isCurrent ? (
                                <>
                                    {/* Past part of the current day */}
                                    <div
                                        className="absolute top-0 left-0 h-full bg-green-300"
                                        style={{
                                            width: `${dayProgress}%`,
                                            zIndex: 1,
                                        }}
                                    />
                                    {/* Remaining part of the current day with animation */}
                                    <div
                                        className="absolute top-0 right-0 h-full bg-green-200 overflow-hidden"
                                        style={{
                                            width: `${100 - dayProgress}%`,
                                            zIndex: 1,
                                        }}
                                    >
                                        {/* Moving rectangle animation */}
                                        <div className="flex flex-row moving-rectangle">
                                            <div className="rectangle"></div>
                                            <div className="rectangle"></div>
                                            <div className="rectangle"></div>
                                        </div>
                                    </div>
                                    <p className="font-semibold relative z-10">{dayFormatted}</p>
                                </>
                            ) : (
                                <p className="font-semibold relative z-10">{dayFormatted}</p>
                            )}
                        </div>
                    );
                })}
            </div>

            <div className="bg-gray-100 p-6 rounded-lg flex-1 overflow-auto">
                <h2 className="text-xl font-bold mb-4">This Week's Summary</h2>
                <p>Calories Burned: <span className="font-semibold">{weeklyData.caloriesBurned}</span></p>
                <p>Trainings Done: <span className="font-semibold">{weeklyData.trainingsDone}</span></p>
                <h3 className="mt-4 font-bold">Achievements:</h3>
                <ul className="list-disc pl-5">
                    {weeklyData.achievements.map((achievement, index) => (
                        <li key={index}>{achievement}</li>
                    ))}
                </ul>
            </div>

            <style jsx>{`
                @keyframes wave {
                    0% {
                        transform: translateX(-100%);
                    }
                    100% {
                        transform: translateX(140%);
                    }
                }
                .moving-rectangle {
                    width: 100%;
                    height: 100%;
                    animation: wave 2s infinite linear;
                    animation-iteration-count: infinite;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .rectangle {
                    width: 20%;
                    height: 100%;
                    background-color: #86efac;
                    margin-right: 5px;
                }
            `}</style>
        </div>
    );
};

export default SmartDashboard;
