import React, { useState, useEffect } from 'react';
import { storage } from '../../firebaseConfig'; // Import Firestore and Storage instances
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Storage methods
import { useUser } from '../../userContext'; // Import user context to get current user

function AccountSetup({ onClose }) {
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 5;
    const [formData, setFormData] = useState({
        sex: '',
        age: '',
        profession: '',
        height: '',
        weight: '',
        goals: '',
        pastInjuries: '',
        timeAvailability: ''
    });
    const [profileExists, setProfileExists] = useState(false); // State to check if profile exists
    const [loading, setLoading] = useState(true); // State for loading
    const { user } = useUser(); // Get the current logged-in user

    useEffect(() => {
        const fetchProfile = async () => {
            if (user) {
                try {
                    // Reference to the JSON file in Firebase Storage using the user's UID
                    const storageRef = ref(storage, `${user.uid}/Profile Sheet/${user.uid}.json`);
                    // Check if the profile sheet exists
                    const url = await getDownloadURL(storageRef);
                    const response = await fetch(url);
                    const data = await response.json();
                    setFormData(data);
                    setProfileExists(true); // Profile exists, show the recap
                } catch (error) {
                    if (error.code === 'storage/object-not-found') {
                        setProfileExists(false); // No profile found
                    } else {
                        console.error('Error fetching profile:', error);
                    }
                } finally {
                    setLoading(false); // Stop loading
                }
            }
        };

        fetchProfile();
    }, [user]);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep((prev) => prev - 1);
        }
    };

    // Save user data as JSON file in Firebase Storage
    const handleSaveData = async () => {
        if (!user) {
            alert('User is not authenticated. Please log in.');
            return;
        }

        try {
            // Convert formData to JSON Blob
            const jsonData = JSON.stringify(formData);
            const blob = new Blob([jsonData], { type: 'application/json' });

            // Reference to the JSON file in Firebase Storage using the user's UID
            const storageRef = ref(storage, `${user.uid}/Profile Sheet/${user.uid}.json`);

            // Upload the JSON Blob to Firebase Storage
            await uploadBytes(storageRef, blob);

            alert('Validation Complete! Your data has been saved as a JSON file.');
            setProfileExists(true); // Set profileExists to true after saving
            onClose();
        } catch (error) {
            console.error('Error saving user data:', error);
            alert('There was an error saving your data. Please try again.');
        }
    };

    const handleUpdateProfile = () => {
        setProfileExists(false); // Show the form to update profile
        setCurrentStep(1); // Reset to the first step
    };

    if (loading) {
        return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    }

    if (profileExists) {
        // Display the recap of the profile sheet
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <div className="w-full max-w-xl bg-white p-6 rounded-lg shadow-md">
                    <h1 className="text-2xl font-bold text-center mb-6">Profile Recap</h1>
                    <div className="text-left space-y-2 mb-6">
                        <p><strong>Sex:</strong> {formData.sex}</p>
                        <p><strong>Age:</strong> {formData.age}</p>
                        <p><strong>Profession:</strong> {formData.profession}</p>
                        <p><strong>Height:</strong> {formData.height} cm</p>
                        <p><strong>Weight:</strong> {formData.weight} kg</p>
                        <p><strong>Goals:</strong> {formData.goals}</p>
                        <p><strong>Past Injuries:</strong> {formData.pastInjuries}</p>
                        <p><strong>Time Availability:</strong> {formData.timeAvailability} per day</p>
                    </div>
                    <div className="flex justify-between">
                        <button
                            onClick={handleUpdateProfile}
                            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                        >
                            Update Profile
                        </button>
                        <button
                            onClick={onClose}
                            className="py-2 px-4 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // Render the 5-step form if no profile sheet is found
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-xl bg-white p-6 rounded-lg shadow-md">
                <h1 className="text-2xl font-bold text-center mb-6">Let's configure Metabol for your personal use</h1>
                <div className="mb-4">
                    <div className="h-2 bg-gray-300 rounded-full">
                        <div
                            className="h-2 bg-indigo-500 rounded-full"
                            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
                        ></div>
                    </div>
                    <p className="text-center mt-2 text-sm text-gray-600">
                        Step {currentStep} of {totalSteps}
                    </p>
                </div>

                <div className="space-y-4 text-center">
                    {/* Render content based on the current step */}
                    <div className="text-xl font-semibold">
                        {currentStep === 1 && (
                            <div className="space-y-4">
                                <p>Step 1: Basic Information</p>
                                <div>
                                    <label className="block text-left mb-2">Gender :</label>
                                    <select
                                        name="sex"
                                        value={formData.sex}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-left mb-2">Age :</label>
                                    <input
                                        type="number"
                                        name="age"
                                        value={formData.age}
                                        onChange={handleChange}
                                        placeholder="Enter your age"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2">Profession :</label>
                                    <input
                                        type="text"
                                        name="profession"
                                        value={formData.profession}
                                        onChange={handleChange}
                                        placeholder="Enter your profession"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                            </div>
                        )}
                        {currentStep === 2 && (
                            <div className="space-y-4">
                                <p>Step 2: Physical Attributes</p>
                                <div>
                                    <label className="block text-left mb-2">Height (cm):</label>
                                    <input
                                        type="number"
                                        name="height"
                                        value={formData.height}
                                        onChange={handleChange}
                                        placeholder="Enter your height"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2">Weight (kg):</label>
                                    <input
                                        type="number"
                                        name="weight"
                                        value={formData.weight}
                                        onChange={handleChange}
                                        placeholder="Enter your weight"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                            </div>
                        )}
                        {currentStep === 3 && (
                            <div className="space-y-4">
                                <p>Step 3: Goals</p>
                                <div>
                                    <label className="block text-left mb-2">Goals:</label>
                                    <input
                                        type="text"
                                        name="goals"
                                        value={formData.goals}
                                        onChange={handleChange}
                                        placeholder="Enter your goals"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                            </div>
                        )}
                        {currentStep === 4 && (
                            <div className="space-y-4">
                                <p>Step 4: Additional Information</p>
                                <div>
                                    <label className="block text-left mb-2">Past Injuries:</label>
                                    <input
                                        type="text"
                                        name="pastInjuries"
                                        value={formData.pastInjuries}
                                        onChange={handleChange}
                                        placeholder="Enter any past injuries"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2">Time Availability (per day):</label>
                                    <input
                                        type="text"
                                        name="timeAvailability"
                                        value={formData.timeAvailability}
                                        onChange={handleChange}
                                        placeholder="Enter your time availability"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                                    />
                                </div>
                            </div>
                        )}
                        {currentStep === 5 && (
                            <div className="space-y-4">
                                <p>Step 5: Review and Validate</p>
                                <div className="text-left space-y-2">
                                    <p><strong>Sex:</strong> {formData.sex}</p>
                                    <p><strong>Age:</strong> {formData.age}</p>
                                    <p><strong>Profession:</strong> {formData.profession}</p>
                                    <p><strong>Height:</strong> {formData.height} cm</p>
                                    <p><strong>Weight:</strong> {formData.weight} kg</p>
                                    <p><strong>Goals:</strong> {formData.goals}</p>
                                    <p><strong>Past Injuries:</strong> {formData.pastInjuries}</p>
                                    <p><strong>Time Availability:</strong> {formData.timeAvailability} per day</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex justify-between mt-6">
                        <button
                            onClick={handleBack}
                            disabled={currentStep === 1}
                            className={`py-2 px-4 rounded-lg ${
                                currentStep === 1
                                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    : 'bg-indigo-500 text-white hover:bg-indigo-600'
                            } transition-colors`}
                        >
                            Back
                        </button>
                        {currentStep < totalSteps ? (
                            <button
                                onClick={handleNext}
                                className="py-2 px-4 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                onClick={handleSaveData} // Call handleSaveData on complete
                                className="py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                            >
                                Validate
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountSetup;
