import React, { useState, useEffect, useCallback, useRef } from 'react';
import { storage } from '../../firebaseConfig';
import { ref, uploadBytes, listAll, getDownloadURL} from 'firebase/storage';
import { useUser } from '../../userContext';
import ReactMarkdown from 'react-markdown';

function ChatArea() {
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);
    const { user } = useUser();
    const messagesEndRef = useRef(null);
    const [currentMessage, setCurrentMessage] = useState('');
    const [hasReceivedFirstChunk, setHasReceivedFirstChunk] = useState(false);

    // New ref for managing chunk processing
    //const pendingChunksRef = useRef('');
    //const isProcessingRef = useRef(false);
    const timeoutRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // New useEffect to scroll when currentMessage updates
    useEffect(() => {
        scrollToBottom();
    }, [currentMessage]);

    const getCurrentDate = () => {
        const date = new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().split('T')[0];
    };

    const saveConversation = async (conversation) => {
        if (!user) {
            console.error('User is not authenticated. Cannot save conversation.');
            return;
        }

        try {
            const jsonData = JSON.stringify(conversation);
            const blob = new Blob([jsonData], { type: 'application/json' });

            const fileName = `${getCurrentDate()}.json`;
            const storageRef = ref(storage, `${user.uid}/User Conversations/${fileName}`);
            const listRef = ref(storage, `${user.uid}/User Conversations/`);

            const res = await listAll(listRef);
            const fileExists = res.items.some(item => item.name === fileName);

            if (fileExists) {
                console.log('Saving the conversation');
            }

            await uploadBytes(storageRef, blob);
            console.log('Conversation saved successfully.');
        } catch (error) {
            console.error('Error saving conversation:', error);
        }
    };

    const loadPastConversations = useCallback(async () => {
        if (!user) {
            console.error('User is not authenticated. Cannot load conversations.');
            return;
        }

        setIsLoading(true);

        try {
            const listRef = ref(storage, `${user.uid}/User Conversations/`);
            const res = await listAll(listRef);

            const currentDate = getCurrentDate();
            const todayFileName = `${currentDate}.json`;

            console.log(todayFileName);

            const todayFileExists = res.items.some(item => item.name === todayFileName);

            if (todayFileExists) {
                const todayRef = res.items.find(item => item.name === todayFileName);
                const url = await getDownloadURL(todayRef);

                const response = await fetch(url);
                const data = await response.json();

                setMessages(data);
                console.log('Today\'s conversation loaded successfully.');
            } else {
                setMessages([]);
                console.log('No conversation for today. Starting a new conversation.');
            }
        } catch (error) {
            console.error('Error loading past conversations:', error);
        } finally {
            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        loadPastConversations()
            .then(() => {
                console.log('Past conversations loaded on component mount.');
            })
            .catch((error) => {
                console.error('Error in loading past conversations on component mount:', error);
            });
    }, [loadPastConversations]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!userInput.trim()) return;

        const newMessage = { sender: 'user', text: userInput };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setUserInput('');
        setIsWaitingForAnswer(true);
        setHasReceivedFirstChunk(false);
        setCurrentMessage('');

        const conversation = [...messages, newMessage].map((msg) => ({
            sender: msg.sender,
            text: msg.text,
        }));

        // WebSocket URL to match your backend WebSocket endpoint
        // const wsUrl = "ws://localhost:8000/ws/task"; 188.166.156.124
        const wsUrl = "wss://api.metabolai.com/ws/task";

        try {
            // Create a new WebSocket connection
            const ws = new WebSocket(wsUrl);

            // WebSocket onopen event handler
            ws.onopen = () => {
                console.log('WebSocket connection opened');
                // Send the conversation data and user UUID to the WebSocket server
                const conversationData = JSON.stringify({
                    conversation: conversation,
                    user_uuid: user.uid,  // Pass the user's UUID
                });
                ws.send(conversationData);
            };

            // WebSocket onmessage event handler (receives the result from the server)
            ws.onmessage = (event) => {
                console.log('Message from server:', event.data);

                const botMessage = { sender: 'bot', text: event.data };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
                setIsWaitingForAnswer(false);
                setHasReceivedFirstChunk(true);

                // Save the updated conversation (user's message and bot's response)
                saveConversation([...messages, newMessage, botMessage]);
            };

            // WebSocket onerror event handler
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                setIsWaitingForAnswer(false);
            };

            // WebSocket onclose event handler
            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };

        } catch (error) {
            console.error('Error:', error);
            setIsWaitingForAnswer(false);
        }
    };


    // Function to process pending chunks with delay
    /*const processPendingChunks = () => {
        isProcessingRef.current = true;

        const processNextChunk = () => {
            if (pendingChunksRef.current.length === 0) {
                isProcessingRef.current = false;
                return;
            }

            const nextChunk = pendingChunksRef.current.shift();

            setCurrentMessage(prevMessage => prevMessage + nextChunk);

            // Continue processing the next chunk after a delay
            timeoutRef.current = setTimeout(processNextChunk, 50); // Adjust delay as needed
        };

        processNextChunk();
    };*/

    // Clean up the timeout when the component unmounts
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const [taskId, setTaskId] = useState(null);
    const [result, setResult] = useState(null);

    const startTask = async () => {
        const response = await fetch("https://188.166.156.124/process", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: "my-data" }),
        });
        const data = await response.json();
        setTaskId(data.task_id);
    };

    useEffect(() => {
        if (taskId) {
            const interval = setInterval(async () => {
                const response = await fetch(`https://188.166.156.124/task/${taskId}`);
                const data = await response.json();

                if (data.status === "completed") {
                    setResult(data.result);
                    clearInterval(interval); // Stop polling when task is done
                }
            }, 2000); // Poll every 2 seconds

            return () => clearInterval(interval); // Cleanup on component unmount
        }
    }, [taskId]);

    return (
        <div className="flex flex-col flex-grow-0 h-full w-full overflow-y-auto">
            <div className="flex flex-col flex-grow overflow-y-auto p-4 bg-white rounded-t-lg" style={{ maxHeight: '70vh' }}>
                {isLoading ? (
                    <div className="flex justify-center items-center w-full h-full">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        {messages.length === 0 ? (
                            <div className="flex justify-center items-center h-full">
                                <p className="text-gray-500">Start a new conversation with Metabol</p>
                            </div>
                        ) : (
                            <div className="flex flex-col flex-grow overflow-y-auto p-4 space-y-4">
                                {messages.map((msg, index) => (
                                    <div
                                        key={index}
                                        className={`p-3 rounded-lg max-w-full md:max-w-lg break-words ${
                                            msg.sender === 'user' ? 'bg-blue-100 text-blue-900 self-end' : 'bg-gray-800 text-white self-start'
                                        }`}
                                        style={{ width: 'fit-content', maxWidth: '75%' }}
                                    >
                                        <ReactMarkdown>{msg.text}</ReactMarkdown>
                                    </div>
                                ))}
                                {isWaitingForAnswer && (
                                    <div className="self-start bg-gray-800 text-white p-3 rounded-lg"
                                         style={{ width: 'fit-content', maxWidth: '75%' }}
                                    >
                                        {hasReceivedFirstChunk ? (
                                            <ReactMarkdown>{currentMessage}</ReactMarkdown>
                                        ) : (
                                            <span className="loading-dots">● ● ●</span>
                                        )}
                                    </div>
                                )}
                                <div ref={messagesEndRef} />
                            </div>
                        )}
                    </>
                )}
            </div>
            <form onSubmit={handleSubmit} className="flex p-4 border-t">
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type a message..."
                    required
                    className="flex-1 p-2 border rounded-md"
                />
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                    Send
                </button>
            </form>
            <button onClick={startTask}>Start Task</button>
            {taskId && !result && <p>Task is in progress...</p>}
            {result && <p>Task completed! Result: {result.message}</p>}
            <style jsx>{`
                .loader {
                    border: 12px solid #f3f3f3;
                    border-top: 12px solid #f56565;
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    animation: spin 2s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                .loading-dots {
                    display: inline-block;
                    animation: jump 1.2s infinite;
                }

                @keyframes jump {
                    0%, 100% { transform: translateY(0); }
                    20% { transform: translateY(-5px); }
                    40% { transform: translateY(0); }
                    60% { transform: translateY(-5px); }
                    80% { transform: translateY(0); }
                }
            `}</style>
        </div>
    );
}

export default ChatArea;
