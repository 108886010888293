import React, { useState, useEffect } from 'react';

const Tutorial = ({ onFinish }) => {
    const [step, setStep] = useState(-1); // Start with -1 to show the welcome screen first

    const steps = [
        {
            message: "This is your Smart Dashboard where you can track your progress and set new goals.",
            highlightElement: "#smart-dashboard"
        },
        {
            message: "Here is the Account Setup section where you can personalize your account.",
            highlightElement: "#account-setup"
        },
        {
            message: "This section contains your past conversations with Metabol.",
            highlightElement: "#past-conversations"
        },
        {
            message: "Lastly, you can review your past workouts here.",
            highlightElement: "#past-workouts"
        },
    ];

    useEffect(() => {
        let element = null;

        if (step >= 0 && steps[step].highlightElement) {
            // Find the element that should be highlighted
            element = document.querySelector(steps[step].highlightElement);
            if (element) {
                // Change only the z-index of the highlighted element to bring it on top
                element.style.zIndex = '50';  // Match ChatBubleLauncher or any necessary z-index
                element.style.position = ''; // Ensure it has relative positioning to respect z-index
                element.classList.add('z-50', 'shadow-[0_0_10px_5px_rgba(255,255,0,0.8)]');
                console.log(element)
            }

            // Clean up by resetting the z-index of the previously highlighted element when the step changes
            return () => {
                if (element) {
                    element.style.zIndex = ''; // Reset to its original value
                    element.style.position = ''; // Reset position if modified
                    element.classList.remove('relative', 'z-50', 'shadow-[0_0_10px_5px_rgba(255,255,0,0.8)]');
                }
            };
        }
    }, [step]);

    const handleNextStep = () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        } else {
            onFinish(); // Close the tutorial when finished
        }
    };

    return (
        <>
            {/* Welcome Screen (only shows if step is -1) */}
            {step === -1 && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex flex-col items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl text-center">
                        <h2 className="text-xl font-bold mb-4">Welcome to Metabol, your new AI coach!</h2>
                        <p>Here's a quick tutorial for you to learn how to get the most out of it!</p>
                        <button
                            onClick={() => setStep(0)} // Start the tutorial when clicked
                            className="mt-4 py-2 px-6 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
                        >
                            Start Tutorial
                        </button>
                    </div>
                </div>
            )}

            {/* Progress Bar (only shows after step is 0 or more) */}
            {step >= 0 && (
                <>
                    {/* Full-screen shadow overlay */}
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-40 pointer-events-none"></div>

                    {/* Progress Bar */}
                    <div className="fixed bottom-0 left-0 w-full z-50">
                        <div className="w-full bg-gray-300 h-2">
                            <div
                                className="bg-indigo-500 h-2"
                                style={{ width: `${((step + 1) / steps.length) * 100}%` }}
                            />
                        </div>
                    </div>

                    {/* Highlighted element message */}
                    <div className="fixed bottom-16 left-1/2 transform -translate-x-1/2 z-50 p-4 bg-white rounded-lg shadow-lg text-center max-w-xl">
                        <h2 className="text-xl font-bold mb-4">{steps[step].message}</h2>
                        <button
                            onClick={handleNextStep}
                            className="mt-4 py-2 px-6 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
                        >
                            {step < steps.length - 1 ? 'Next' : "Let's start"}
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default Tutorial;
