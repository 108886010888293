import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithEmailAndPassword, signInWithPopup } from '../../firebaseConfig';
import { useUser } from '../../userContext';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { loginUser } = useUser(); // Use loginUser from context

    const notifyServerUserLoggedIn = async (userUUID) => {
        try {
            const response = await fetch(`https://api.metabolai.com/user_logged_in?user_uuid=${userUUID}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error(`Failed to notify server: ${response.statusText}`);
            }
            console.log('User UUID successfully sent to server');
        } catch (error) {
            console.error('Error notifying server:', error);
        }
    };


    const handleEmailLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const userData = {
                uid: user.uid,
                email: user.email,
                // Add any other user information you need
            };
            loginUser(userData); // Save the user data to context
            await notifyServerUserLoggedIn(user.uid);
            navigate('/account');
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGoogleLogin = async () => {
        setError('');
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const userData = {
                uid: user.uid,
                email: user.email,
                // Add any other user information you need
            };
            loginUser(userData); // Save the user data to context
            await notifyServerUserLoggedIn(user.uid);
            navigate('/account');
        } catch (err) {
            setError(err.message);
        }
    };

    const handleCreateAccountClick = () => {
        navigate('/signup');
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Log in to Metabol</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form className="space-y-4" onSubmit={handleEmailLogin}>
                    <div>
                        <label htmlFor="email" className="sr-only">Email address</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter email address"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter password"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="text-right">
                        <a href="/" className="text-sm text-indigo-500 hover:underline">Forgot password?</a>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors"
                    >
                        Log In
                    </button>
                </form>
                <p className="text-center text-gray-600 mt-4">
                    Don't have an account? <a href="/signup" onClick={handleCreateAccountClick} className="text-indigo-500 hover:underline">Create an account</a>
                </p>
                <div className="flex items-center my-4">
                    <hr className="flex-grow border-gray-300" />
                    <span className="px-4 text-gray-500">or</span>
                    <hr className="flex-grow border-gray-300" />
                </div>
                <button
                    onClick={handleGoogleLogin}
                    className="w-full py-2 border border-gray-300 rounded-lg flex items-center justify-center hover:bg-gray-50 transition-colors"
                >
                    <img
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                        alt="Google logo"
                        className="w-5 h-5 mr-2"
                    />
                    Log in with Google
                </button>
                <p className="text-center text-gray-500 text-xs mt-4">
                    By continuing, you agree to our <a href="/" className="text-indigo-500 hover:underline">privacy policy</a> and <a href="/" className="text-indigo-500 hover:underline">terms of service</a>.
                </p>
            </div>
        </div>
    );
}

export default LoginPage;
