import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, createUserWithEmailAndPassword, signInWithPopup } from '../../firebaseConfig';
import { useUser } from '../../userContext'; // Import the useUser hook
import { storage } from '../../firebaseConfig'; // Import Firebase storage
import { ref, uploadBytes } from 'firebase/storage'; // Import Firebase storage functions

function SignupPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { loginUser } = useUser(); // Use loginUser from context

    const notifyServerUserLoggedIn = async (userUUID) => {
        try {
            const response = await fetch(`https://api.metabolai.com/user_signed_in?user_uuid=${userUUID}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error(`Failed to notify server: ${response.statusText}`);
            }
            console.log('User UUID successfully sent to server');
        } catch (error) {
            console.error('Error notifying server:', error);
        }
    };

    // Function to create an account.json file in Firebase Storage
    const createAccountFile = async (userId) => {
        const accountData = {
            justSignedUp: 'true',
        };
        const jsonData = JSON.stringify(accountData);
        const blob = new Blob([jsonData], { type: 'application/json' });

        // Create a reference to the location in Firebase Storage
        const accountRef = ref(storage, `${userId}/Account/account.json`);

        try {
            // Upload the account.json file
            await uploadBytes(accountRef, blob);
            console.log('Account file created successfully');
        } catch (err) {
            console.error('Error uploading account.json:', err);
        }
    };

    // Handle Email Sign Up
    const handleEmailSignUp = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const userData = {
                uid: user.uid,
                email: user.email,
            };

            // Create the account.json file in Firebase Storage
            await createAccountFile(user.uid);

            loginUser(userData); // Save the user data to context
            await notifyServerUserLoggedIn(user.uid);
            navigate('/account'); // Navigate to account page
        } catch (err) {
            setError(err.message);
        }
    };

    // Handle Google Sign Up
    const handleGoogleSignUp = async () => {
        setError('');
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const userData = {
                uid: user.uid,
                email: user.email,
            };

            // Create the account.json file in Firebase Storage
            await createAccountFile(user.uid);

            loginUser(userData); // Save the user data to context
            await notifyServerUserLoggedIn(user.uid);
            navigate('/account'); // Navigate to account page
        } catch (err) {
            setError(err.message);
        }
    };

    const handleLogInClick = () => {
        navigate('/login');
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Sign up to Metabol</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form className="space-y-4" onSubmit={handleEmailSignUp}>
                    <div>
                        <label htmlFor="email" className="sr-only">Email address</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter email address"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter password"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="text-right">
                        <a href="/" className="text-sm text-indigo-500 hover:underline">Forgot password?</a>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors"
                    >
                        Sign Up
                    </button>
                </form>
                <p className="text-center text-gray-600 mt-4">
                    Already have an account? <a href="/login" onClick={handleLogInClick} className="text-indigo-500 hover:underline">Log in</a>
                </p>
                <div className="flex items-center my-4">
                    <hr className="flex-grow border-gray-300" />
                    <span className="px-4 text-gray-500">or</span>
                    <hr className="flex-grow border-gray-300" />
                </div>
                <button
                    onClick={handleGoogleSignUp}
                    className="w-full py-2 border border-gray-300 rounded-lg flex items-center justify-center hover:bg-gray-50 transition-colors"
                >
                    <img
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                        alt="Google logo"
                        className="w-5 h-5 mr-2"
                    />
                    Sign up with Google
                </button>
                <p className="text-center text-gray-500 text-xs mt-4">
                    By continuing, you agree to our <a href="/" className="text-indigo-500 hover:underline">privacy policy</a> and <a href="/" className="text-indigo-500 hover:underline">terms of service</a>.
                </p>
            </div>
        </div>
    );
}

export default SignupPage;
