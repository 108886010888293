/*import { useState } from 'react';
import { Link } from 'react-router-dom';
import {wait} from "@testing-library/user-event/dist/utils";*/
import Navbar from "../../Components/NavBar/navBar";
import Footer from "../../Components/Footer/footer";
import SalesScript from "../../Components/SalesScript/salesScript";

function LandingPage() {
    return (
        <div>
            <Navbar></Navbar>
            <SalesScript></SalesScript>
            <Footer></Footer>
        </div>
    );
}

export default LandingPage;
