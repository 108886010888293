import React from 'react';
import './App.css';
import {UserProvider} from './userContext'
import LandingPage from "./Pages/LandingPage/landingPage";
import UserSpacePage from "./Pages/UserSpacePage/userSpacePage";
import LoginPage from "./Pages/LoginPage/loginPage";
import SignupPage from "./Pages/SignupPage/signupPage";
import Footer from "./Components/Footer/footer";
import ProtectedRoute from './Components/ProtectedRoute/protectedRoute';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {

    return (
        <UserProvider>
            <Router>
                <div>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/account" element={<ProtectedRoute element={UserSpacePage} />} />
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/signup" element={<SignupPage/>}/>
                    </Routes>
                    <Footer></Footer>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;
